import { ThesisListProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import HeroInner from "@schemas/modules/HeroInner";
import { detail, heading, loremIpsumParagraph } from "@schemas/presets";

const schema: Schema.Template<ThesisListProps> = {
	dataPacks: ["THESIS"],
	schemaType: "template",
	displayName: "Thesis List",
	component: "ThesisList",

	type: { label: "Thesis", value: "thesis" },

	content: [
		{
			type: "ComponentArray",
			title: "Hero",
			maxItems: 1,
			whiteList: ["HeroInner"],
			contentType: "modules",
			key: "heroSection",
			mandatory: true,
		},
		{ ...heading, key: "templateTitle", hideable: false, isMockup: true },
		{ ...detail, title: "Subtitle" },
		{
			type: "CheckGroup",
			title: "Select filter options",
			key: "templateOptions",
			options: [
				{
					title: "Search feature",
					name: "search",
				},
				{
					title: "Filter (Areas)",
					name: "filterAreas",
				},
				{
					title: "Filter (Centers)",
					name: "filterCenters",
				},
			],
		},
		{
			type: "TextField",
			title: "No Results text",
			key: "noResultsText",
			mandatory: true,
			humanReadable: true,
		},
		{
			title: "",
			type: "ReferenceField",
			key: "data",
			sources: [{ structuredData: "THESIS" }],
			selectionType: ["auto"],
			mandatory: true,
		},
		{
			title: "Items per page",
			type: "NumberField",
			key: "itemsPerPage",
			min: 6,
			mandatory: true,
		},
		{
			type: "ComponentArray",
			title: "Related Content",
			whiteList: [
				"BasicContent",
				"BasicText",
				"ContactInfo",
				"FeaturedFacts",
				"ImageBanner",
				"IntroForm",
				"Quote",
				"Table",
				"VideoPlaylist",
				"Widget",
				"Wysiwyg",
			],
			contentType: "modules",
			key: "relatedContent",
		},
	],

	config: [
		{
			type: "RadioGroup",
			title: "Pagination type",
			key: "paginationType",
			options: [
				{ name: "normal", value: "normal", title: "Normal pagination" },
				{ name: "infinite", value: "infinite", title: "Infinite scroll" },
			],
			mandatory: true,
		},
	],

	default: {
		type: "template",
		templateType: "ThesisList",
		heroSection: {
			component: "Section",
			name: "Hero",
			modules: [{ ...HeroInner.default }],
		},
		templateTitle: { content: "Lorem ipsum", tag: "h1" },
		detail: loremIpsumParagraph,
		noResultsText:
			"Sorry, no results were found. Please try a different search.",
		relatedContent: {
			component: "Section",
			name: "Related Content",
			modules: [],
		},
		itemsPerPage: 9,
		activePage: 1,
		data: {
			sources: [{ structuredData: "THESIS" }],
			mode: "auto",
			order: "recent-DESC",
		},
		paginationType: "normal",
	},

	thumbnails: {
		"1x": "/thumbnails/templates/ThesisList/thumbnail@1x.png",
		"2x": "/thumbnails/templates/ThesisList/thumbnail@2x.png",
	},
};

export default schema;
