import { Component } from "@griddo/core";
import * as React from "react";

// Types
import type { AcademicAreaSelectorProps } from "./AcademicAreaSelector";
import type { CenterSelectorProps } from "./CenterSelector";
import type { CheckboxFieldProps } from "./CheckboxField";
import type { EmailFieldProps } from "./EmailField";
import type { EntryDatesSelectorProps } from "./EntryDatesSelector";
import type { EventCallsSelectorProps } from "./EventCallsSelector";
import type { LandingAcademicAreaSelectorProps } from "./LandingAcademicAreaSelector";
import type { LandingProgramSelectorProps } from "./LandingProgramSelector";
import type { LandingProgramTypeSelectorProps } from "./LandingProgramTypeSelector";
import type { LegalFieldProps } from "./LegalField";
import type { NumberFieldProps } from "./NumberField";
import type { PasswordFieldProps } from "./PasswordField";
import type { PhoneFieldProps } from "./PhoneField";
import type { ProgramSelectorProps } from "./ProgramSelector";
import type { ProgramTypeSelectorProps } from "./ProgramTypeSelector";
import type { RadioButtonFieldProps } from "./RadioButtonField";
import type { SelectorFieldProps } from "./SelectorField";
import type { TextareaFieldProps } from "./TextareaField";
import type { TextFieldProps } from "./TextField";

// Components
const AcademicAreaSelector = React.lazy(() => import("./AcademicAreaSelector"));
const CenterSelector = React.lazy(() => import("./CenterSelector"));
const CheckboxField = React.lazy(() => import("./CheckboxField"));
const EmailField = React.lazy(() => import("./EmailField"));
const EntryDatesSelector = React.lazy(() => import("./EntryDatesSelector"));
const EventCallsSelector = React.lazy(() => import("./EventCallsSelector"));
const LandingAcademicAreaSelector = React.lazy(
	() => import("./LandingAcademicAreaSelector")
);
const LandingProgramSelector = React.lazy(
	() => import("./LandingProgramSelector")
);
const LandingProgramTypeSelector = React.lazy(
	() => import("./LandingProgramTypeSelector")
);
const LegalField = React.lazy(() => import("./LegalField"));
const NumberField = React.lazy(() => import("./NumberField"));
const PasswordField = React.lazy(() => import("./PasswordField"));
const PhoneField = React.lazy(() => import("./PhoneField"));
const ProgramSelector = React.lazy(() => import("./ProgramSelector"));
const ProgramTypeSelector = React.lazy(() => import("./ProgramTypeSelector"));
const RadioButtonField = React.lazy(() => import("./RadioButtonField"));
const SelectorField = React.lazy(() => import("./SelectorField"));
const TextareaField = React.lazy(() => import("./TextareaField"));
const TextField = React.lazy(() => import("./TextField"));

// Components for <GriddoComponent>
const components = {
	AcademicAreaSelector,
	CenterSelector,
	CheckboxField,
	EmailField,
	EntryDatesSelector,
	LandingAcademicAreaSelector,
	LandingProgramSelector,
	LandingProgramTypeSelector,
	EventCallsSelector,
	LegalField,
	NumberField,
	PasswordField,
	PhoneField,
	ProgramSelector,
	ProgramTypeSelector,
	RadioButtonField,
	SelectorField,
	TextareaField,
	TextField,
};

// Types for <GriddoComponent>
type ComponentProps =
	| AcademicAreaSelectorProps
	| CenterSelectorProps
	| CheckboxFieldProps
	| EmailFieldProps
	| EntryDatesSelectorProps
	| EventCallsSelectorProps
	| LandingAcademicAreaSelectorProps
	| LandingProgramSelectorProps
	| LandingProgramTypeSelectorProps
	| LegalFieldProps
	| NumberFieldProps
	| PasswordFieldProps
	| PhoneFieldProps
	| ProgramSelectorProps
	| ProgramTypeSelectorProps
	| RadioButtonFieldProps
	| SelectorFieldProps
	| TextareaFieldProps
	| TextFieldProps;

// Component named export
export {
	AcademicAreaSelector,
	CenterSelector,
	CheckboxField,
	EmailField,
	EntryDatesSelector,
	EventCallsSelector,
	LandingAcademicAreaSelector,
	LandingProgramSelector,
	LandingProgramTypeSelector,
	LegalField,
	NumberField,
	PasswordField,
	PhoneField,
	ProgramSelector,
	ProgramTypeSelector,
	RadioButtonField,
	SelectorField,
	TextareaField,
	TextField,
	// ...
};

// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// ---------------------------- You shall not pass! ----------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// ---------------------------------------------------------------- Gandalf ----
// -----------------------------------------------------------------------------

// Griddo bundle export
export default components;

/**
 * `<GriddoFormField>`
 *
 * Render components with their Types dinamically and wrapped them with Griddo
 * functionalities.
 *
 * @example
 * <GriddoFormField component="EmailField" {...emailFieldProps} />
 *
 * Check out the [documentation](https://griddoio.notion.site/GriddoComponent-y-GriddoModule-en-la-instancia-bad16303bb4b49e0b696b62d1f6c40e9)
 */
export const GriddoFormField = (props: ComponentProps) => (
	<Component libComponents={components} {...props} />
);
