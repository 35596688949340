import CustomAdmissionForm from "./CustomAdmissionForm";
import CustomBasicForm from "./CustomBasicForm";
import CustomContactForm from "./CustomContactForm";
import CustomDownloadForm from "./CustomDownloadForm";
import CustomEventForm from "./CustomEventForm";
import CustomLandingForm from "./CustomLandingForm";
import CustomPostgraduateEventForm from "./CustomPostgraduateEventForm";

const formTemplates = {
	CustomAdmissionForm,
	CustomBasicForm,
	CustomContactForm,
	CustomDownloadForm,
	CustomEventForm,
	CustomLandingForm,
	CustomPostgraduateEventForm,
};

export { formTemplates };
