import { TextareaFieldProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	crmKey,
	fieldWidth,
	helptext,
	label,
	mandatory,
} from "@schemas/forms/presets";

const schema: Schema.FormField<TextareaFieldProps> = {
	schemaType: "formComponent",
	component: "TextareaField",
	displayName: "Textarea Field",

	configTabs: [
		{
			title: "Content",
			fields: [
				{ ...crmKey },
				{ ...label },
				{ ...helptext },
				{ ...mandatory },
				{ ...fieldWidth },
			],
		},
	],

	default: {
		component: "TextareaField",
		fieldWidth: "100",
	},
};

export default schema;
