import { InnerEventsDistributorProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	additional,
	animation,
	content,
	emptyState,
	heading,
	link,
	loremIpsumParagraph,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<InnerEventsDistributorProps> = {
	schemaType: "module",
	component: "InnerEventsDistributor",
	category: "distributors",
	displayName: "Events Distributor",
	dataPacks: ["EVENTS"],

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...additional },
				{ ...heading, mandatory: false },
				{ ...content },
				{
					...emptyState,
				},
				{
					title: "Events",
					type: "ReferenceField",
					sources: [
						{ structuredData: "EVENT" },
						{ structuredData: "EVENT_AUTO" },
					],
					key: "data",
					mandatory: true,
				},
				{ ...link, hideable: true },
			],
		},

		{
			title: "config",
			fields: [{ ...verticalSpacing }, { ...animation }],
		},
	],

	default: {
		component: "InnerEventsDistributor",
		additional: null,
		title: {
			content: "Eventos",
			tag: "h3",
		},
		content: loremIpsumParagraph,
		emptyState:
			"Parece que no hay ningún evento próximamente, pero seguro que los habrá pronto. No dudes en visitar nuestro portal de comunicación y prensa para no perderte nada.",
		hasDistributorData: true,
		data: {
			mode: "auto",
			order: "recent-DESC",
			sources: [{ structuredData: "EVENT", globalOperator: "OR" }],
		},
		link: {
			component: "Link",
			variant: "button2",
			text: "Ver todos los eventos",
		},
		verticalSpacing: "medium",
		animation: "none",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/EventsDistributor/thumbnail@1x.png",
		"2x": "/thumbnails/modules/EventsDistributor/thumbnail@2x.png",
	},
};

export default schema;
