import { CustomPostgraduateEventFormProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { ctaLabel, utmFields } from "@schemas/forms/presets";
import {
	detail,
	getThemeOptions,
	heading,
	loremIpsumParagraph,
	themeSelector,
} from "@schemas/presets";

const schema: Schema.FormTemplate<CustomPostgraduateEventFormProps> = {
	schemaType: "formTemplate",
	displayName: "Postgraduate Event Form",
	component: "CustomPostgraduateEventForm",

	content: [
		{
			type: "NoteField",
			title: "Form path",
			key: "note",
			value: {
				title: "",
				text: 'This form is sent to the "contacts" and "leads" paths in the CRM. It also relates the contact and the lead to the event.',
			},
		},
		{ ...heading, isMockup: true, mandatory: false },
		{ ...detail },
		{ ...ctaLabel },
		{
			type: "TextField",
			key: "eventCode",
			title: "Event code",
			mandatory: true,
		},
		{
			type: "TextField",
			key: "eventId",
			title: "Event ID",
			mandatory: true,
		},
		{
			type: "TextField",
			title: "Title (1st step)",
			key: "title1",
			humanReadable: true,
			hideable: true,
		},
		{
			type: "TextField",
			title: "CTA Label (1st step)",
			key: "ctaLabel1",
			humanReadable: true,
			hideable: true,
		},
		{
			type: "FormFieldArray",
			title: "Form fields (1st step)",
			key: "formFields1",
			whiteList: [
				"TextField",
				"TextareaField",
				"EmailField",
				"PhoneField",
				"NumberField",
				"SelectorField",
				"CenterSelector",
				"ProgramSelector",
				"RadioButtonField",
				"CheckboxField",
				"SectionTitle",
				"LegalField",
			],
		},
		{
			type: "TextField",
			title: "Title (2nd step)",
			key: "title2",
			humanReadable: true,
			hideable: true,
		},
		{
			type: "FormFieldArray",
			title: "Form fields (2nd step)",
			key: "formFields2",
			whiteList: [
				"TextField",
				"TextareaField",
				"EmailField",
				"PhoneField",
				"NumberField",
				"SelectorField",
				"CenterSelector",
				"ProgramSelector",
				"RadioButtonField",
				"CheckboxField",
				"SectionTitle",
				"LegalField",
			],
		},
		{
			type: "FieldGroup",
			title: "Extra fields",
			key: "extraFields",
			fields: [
				{
					type: "TextField",
					title: "Request Channel CRM key",
					key: "requestyChannelCrmKey",
					helptext: "For example: leadsourcecode.",
				},
				{
					type: "TextField",
					title: "Request channel value (contact)",
					key: "requestChannelContact",
				},
				{
					type: "TextField",
					title: "Request channel value (lead)",
					key: "requestChannelLead",
				},
				{
					type: "TextField",
					title: "Contact source CRM key",
					key: "contactSourceCrmKey",
					helptext: "For example: com_origendelcontacto.",
				},
				{
					type: "TextField",
					title: "Contact source value",
					key: "contactSource",
				},
			],
		},
		{
			type: "ArrayFieldGroup",
			arrayType: "dropDown",
			title: "Changes in CRM key between 'contact' and 'lead'",
			key: "changesCrmKey",
			name: "Change",
			fields: [
				{
					type: "TextField",
					title: "Contact CRM key",
					key: "contactKey",
					mandatory: true,
				},
				{
					type: "TextField",
					title: "Lead CRM key",
					key: "leadKey",
					helptext: "Leave empty if value must be deleted in 'lead' request.",
				},
			],
		},
		{
			type: "UniqueCheck",
			title: "Add request date",
			key: "addRequestDate",
			options: [
				{
					title: "Add com_fechaultimapeticion if updating lead info.",
				},
			],
		},
		{
			type: "TextField",
			title: "com_gestionado value",
			key: "com_gestionado",
			helptext: "com_gestionado value to send if updating lead info.",
			mandatory: false,
		},
		{
			type: "UniqueCheck",
			title: "UTM fields",
			key: "sendUtmFields",
			options: [
				{
					title: "Send UTM fields in 'contact' request.",
				},
			],
		},
		{ ...utmFields },
	],

	config: [
		{
			...themeSelector,
			options: getThemeOptions([
				"default",
				"default-alt",
				"accent-alt",
				"inverse",
			]),
		},
		{
			title: "Show decoration",
			key: "decoration",
			type: "RadioGroup",
			options: [
				{ name: "yes", value: true, title: "Yes" },
				{ name: "no", value: false, title: "No" },
			],
			mandatory: true,
		},
	],

	default: {
		type: "formTemplate",
		templateType: "CustomPostgraduateEventForm",
		title: { content: "Lorem ipsum", tag: "h3" },
		detail: loremIpsumParagraph,
		ctaLabel: "Solicitar más información",
		title1: "Información Personal",
		ctaLabel1: "Siguiente paso",
		formFields1: [],
		title2: "Otra Información",
		formFields2: [],
		requestyChannelCrmKey: "leadsourcecode",
		requestChannelContact: "1",
		requestChannelLead: "100000013",
		contactSourceCrmKey: "com_origendelcontacto",
		contactSource: "181410003",
		changesCrmKey: [
			{
				contactKey: "com_programasdeinteres",
				leadKey: "com_programainteresado4txt",
			},
			{ contactKey: "com_egresado" },
			{ contactKey: "com_universidad" },
			{ contactKey: "com_estudios" },
			{ contactKey: "com_promocion" },
			{ contactKey: "jobtitle" },
			{ contactKey: "com_empresaorganizacion" },
			{ contactKey: "com_seguirinformado" },
			{ contactKey: "com_comonosconocio" },
			{ contactKey: "com_observaciones" },
		],
		addRequestDate: true,
		com_gestionado: "181410000",
		sendUtmFields: false,
		utmFields: [
			{ crmKey: "com_utm_medium", paramName: "utm_medium" },
			{ crmKey: "com_utm_source", paramName: "utm_source" },
			{ crmKey: "com_utm_campaign", paramName: "utm_campaign" },
		],
		subtheme: "default",
		decoration: false,
	},

	thumbnails: {
		"1x": "/thumbnails/forms/CustomPostgraduateEventForm/thumbnail@1x.png",
		"2x": "/thumbnails/forms/CustomPostgraduateEventForm/thumbnail@2x.png",
	},
};

export default schema;
