import { ProgramDetailProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import BasicContent from "@schemas/modules/BasicContent";
import CypherCollection from "@schemas/modules/CypherCollection";
import DownloadCollection from "@schemas/modules/DownloadCollection";
import FAQCollection from "@schemas/modules/FAQCollection";
import PeopleDistributor from "@schemas/modules/PeopleDistributor";
import ProgramIntroForm from "@schemas/modules/ProgramIntroForm";
import ProgramNavigation from "@schemas/modules/ProgramNavigation";
import Quote from "@schemas/modules/Quote";
import StepCollection from "@schemas/modules/StepCollection";
import StudyPlan from "@schemas/modules/StudyPlan";
import TestimonialDistributor from "@schemas/modules/TestimonialDistributor";
import VerticalTabs from "@schemas/modules/VerticalTabs";
import { additional, heading } from "@schemas/presets";

const schema: Schema.Template<ProgramDetailProps> = {
	schemaType: "template",
	component: "ProgramDetail",
	displayName: "Program",
	dataPacks: ["PROGRAMS"],

	type: {
		label: "Program",
		value: "program",
		mode: "detail",
	},

	content: [
		{
			...heading,
			title: "Hero title",
			key: "heroTitle",
			default: { content: "Lorem ipsum", tag: "h1" },
		},
		{ ...additional },
		{
			type: "Wysiwyg",
			full: false,
			title: "Abstract",
			key: "abstract",
			hideable: true,
			isMockup: true,
			placeholder: "Type an abstract text",
			humanReadable: true,
		},
		{
			type: "ImageField",
			title: "Image",
			key: "image",
			hideable: true,
		},
		{
			title: "Document to download",
			type: "ReferenceField",
			key: "data",
			sources: [{ structuredData: "DOCUMENT" }],
			selectionType: ["manual"],
			maxItems: 1,
		},
		{
			title: "Highlighted tag",
			type: "AsyncSelect",
			key: "tag",
			source: "PROGRAM_TAG",
			hideable: true,
			helptext: "Shown on the distributor and list card",
		},
		{
			title: "Price",
			type: "TextField",
			key: "price",
			hideable: true,
		},
		{
			title: "Schedule",
			type: "TextField",
			key: "schedule",
			hideable: true,
		},
		{
			type: "ComponentArray",
			title: "Navigation",
			key: "navigation",
			whiteList: ["ProgramNavigation"],
			contentType: "modules",
			maxItems: 1,
			mandatory: true,
		},
		{
			type: "ComponentArray",
			title: "Main Content",
			key: "mainSection",
			whiteList: [
				"AccordionCollection",
				"BasicContent",
				"BasicText",
				"CardCollection",
				"ChartCollection",
				"ContactInfo",
				"Countdown",
				"CypherCollection",
				"DownloadCollection",
				"FAQCollection",
				"FeaturedAudiovisual",
				"FeaturedBlock",
				"FeaturedContent",
				"FeaturedFacts",
				"FeaturedPrices",
				"FullVideo",
				"HighlightedCardCollection",
				"HighlightedContent",
				"HorizontalTabs",
				"ImageBanner",
				"ImageGallery",
				"ProgramIntroForm",
				"LogoCollection",
				"Milestones",
				"Principles",
				"Quote",
				"SocialShare",
				"StepCollection",
				"StickyTabs",
				"Spacer",
				"Table",
				"TikTokCollection",
				"TypographicVideo",
				"VerticalTabs",
				"TextCarousel",
				"TextHeroCountdown",
				"VideoGallery",
				"VideoPlaylist",
				"Widget",
				"Wysiwyg",
			],
			contentType: "modules",
			mandatory: true,
		},
		{
			type: "FieldGroup",
			title: "Comparator fields",
			key: "comparator",
			fields: [
				{
					type: "NoteField",
					title: "",
					key: "comparatorNote",
					value: {
						title: "",
						text: "The fields below will not be displayed on the program page, but rather in the information that will appear in the study comparison tool. It is recommended that all fields be filled out to maintain consistency across all programs and so that students can find all the information they need to compare.",
					},
				},
				{
					type: "NumberField",
					title: "Practices hours",
					key: "practicesHours",
					hideable: true,
				},
				{
					type: "Wysiwyg",
					full: false,
					title: "Resume",
					key: "resume",
					hideable: true,
					humanReadable: true,
				},
				{
					type: "Wysiwyg",
					full: false,
					title: "Career opportunities",
					key: "opportunities",
					hideable: true,
					humanReadable: true,
				},
				{
					type: "Wysiwyg",
					full: false,
					title: "Candidate profile",
					key: "profile",
					hideable: true,
					humanReadable: true,
				},
				{
					type: "ReferenceField",
					title: "Exchanges",
					key: "agreementsData",
					sources: [{ structuredData: "AGREEMENTS" }],
					hideable: true,
				},
			],
		},
		{
			type: "FieldGroup",
			title: "Price fields (comparator)",
			key: "price",
			fields: [
				{
					type: "TextField",
					title: "Registration payment",
					key: "registrationPayment",
					hideable: true,
				},
				{
					type: "TextField",
					title: "Monthly payment",
					key: "monthlyPayment",
					hideable: true,
				},
				{
					type: "TextField",
					title: "Monthly fees",
					key: "monthlyFees",
					hideable: true,
				},
				{
					type: "TextField",
					title: "Total",
					key: "totalPrice",
					hideable: true,
				},
			],
		},
		{
			type: "FieldGroup",
			key: "apiData",
			title: "API Data",
			fields: [
				{
					type: "DateField",
					title: "Starts On",
					key: "intake",
					hideable: true,
					disabled: true,
				},
				{
					type: "TextField",
					title: "Duration",
					key: "duration",
					hideable: true,
					readonly: true,
				},
				{
					type: "TextField",
					title: "Seats",
					key: "seats",
					hideable: true,
					readonly: true,
				},
				{
					title: "Quick facts",
					type: "MultiCheckSelectGroup",
					key: "categories",
					filled: true,
					disabled: true,
					elements: [
						{
							placeholder: "Centers",
							key: "centers",
							source: "CENTER",
						},
						{
							placeholder: "Schools",
							key: "schools",
							source: "SCHOOL",
						},
						{
							placeholder: "Program type",
							key: "programTypes",
							source: "PROGRAM_TYPE",
							mandatory: true,
						},
						{
							placeholder: "Certification",
							key: "certifications",
							source: "CERTIFICATION",
						},
						{
							placeholder: "Academic area",
							key: "academicAreas",
							source: "ACADEMIC_AREA",
						},
						{
							placeholder: "Languages",
							key: "languages",
							source: "LANGUAGE",
						},
						{
							placeholder: "Modalities",
							key: "modalities",
							source: "MODALITY",
						},
						{
							placeholder: "Formats",
							key: "formats",
							source: "FORMAT",
						},
						{
							placeholder: "Calls for registrations",
							key: "registrations",
							source: "REGISTRATION",
						},
						{
							placeholder: "Campus",
							key: "campus",
							source: "CAMPUS",
						},
					],
				},
				{
					type: "TextField",
					title: "Id API (titIdWeb)",
					key: "idAPI",
					readonly: true,
				},
				{
					type: "TextField",
					title: "Acronym (titSiglas)",
					key: "acronym",
					readonly: true,
				},
				{
					type: "TextField",
					title: "Admission dates",
					key: "admissionDates",
					readonly: true,
				},
				{
					type: "TextField",
					title: "Admission URL",
					key: "admissionUrl",
					readonly: true,
				},
				{
					type: "TextField",
					key: "centerOrder",
					title: "Center order",
					computed: (page) => {
						return page?.template.centerOrder || "";
					},
					readonly: true,
				},
				{
					type: "TextField",
					key: "certificationOrder",
					title: "Certification order",
					computed: (page) => {
						return page?.template.certificationOrder || "";
					},
					readonly: true,
				},
				{
					type: "TextField",
					title: "Theme",
					key: "centerTheme",
					readonly: true,
				},
			],
		},
	],

	config: [
		{
			type: "UniqueCheck",
			key: "breadcrumbsDesktop",
			title: "Breadcrumbs Desktop/Tablet",
			options: [{ title: "Display in tablet and desktop" }],
		},
		{
			type: "UniqueCheck",
			key: "breadcrumbs",
			title: "Breadcrumbs Mobile",
			options: [{ title: "Display in mobile" }],
		},
		{
			type: "RadioGroup",
			key: "titleSize",
			title: "Title Size",
			mandatory: true,
			options: [
				{ name: "s", value: "s", title: "s" },
				{ name: "m", value: "m", title: "m" },
				{ name: "l", value: "l", title: "l" },
				{ name: "xl", value: "xl", title: "xl" },
			],
		},
	],

	default: {
		type: "template",
		templateType: "ProgramDetail",
		heroTitle: { content: "", tag: "h1" },
		additional: null,
		navigation: {
			component: "Section",
			name: "Navigation",
			modules: [{ ...ProgramNavigation.default }],
		},
		hasDistributorData: true,
		data: {
			mode: "manual",
			fixed: [],
		},
		mainSection: {
			component: "Section",
			name: "Main Section",
			modules: [
				{ ...ProgramIntroForm.default },
				{ ...BasicContent.default },
				{ ...CypherCollection.default, subtheme: "inverse" },
				{ ...StudyPlan.default },
				{ ...Quote.default, subtheme: "accent" },
				{ ...DownloadCollection.default },
				{ ...TestimonialDistributor.default },
				{ ...StepCollection.default },
				{ ...PeopleDistributor.default },
				{ ...VerticalTabs.default },
				{ ...FAQCollection.default, subtheme: "inverse" },
			],
		},
		agreementsData: {
			mode: "auto",
			order: "alpha-ASC",
			sources: [{ structuredData: "AGREEMENTS" }],
		},
		titleSize: "m",
		breadcrumbsDesktop: true,
		breadcrumbs: false,
	},

	thumbnails: {
		"onexed-theme": {
			"1x": "/thumbnails/templates/ProgramDetail/onexed@1x.png",
			"2x": "/thumbnails/templates/ProgramDetail/onexed@2x.png",
		},
		"1x": "/thumbnails/templates/ProgramDetail/thumbnail@1x.png",
		"2x": "/thumbnails/templates/ProgramDetail/thumbnail@2x.png",
	},
};

export default schema;
