import AcademicAreaSelector from "./AcademicAreaSelector";
import CenterSelector from "./CenterSelector";
import CheckboxField from "./CheckboxField";
import EmailField from "./EmailField";
import EntryDatesSelector from "./EntryDatesSelector";
import EventCallsSelector from "./EventCallsSelector";
import HiddenField from "./HiddenField";
import LandingAcademicAreaSelector from "./LandingAcademicAreaSelector";
import LandingProgramSelector from "./LandingProgramSelector";
import LandingProgramTypeSelector from "./LandingProgramTypeSelector";
import LegalField from "./LegalField";
import NumberField from "./NumberField";
import PasswordField from "./PasswordField";
import PhoneField from "./PhoneField";
import ProgramSelector from "./ProgramSelector";
import ProgramTypeSelector from "./ProgramTypeSelector";
import RadioButtonField from "./RadioButtonField";
import SectionTitle from "./SectionTitle";
import SelectorField from "./SelectorField";
import TextareaField from "./TextareaField";
import TextField from "./TextField";

const formFields = {
	AcademicAreaSelector,
	CenterSelector,
	CheckboxField,
	EmailField,
	EntryDatesSelector,
	LandingAcademicAreaSelector,
	LandingProgramSelector,
	LandingProgramTypeSelector,
	EventCallsSelector,
	HiddenField,
	LegalField,
	NumberField,
	PasswordField,
	PhoneField,
	ProgramSelector,
	ProgramTypeSelector,
	RadioButtonField,
	SectionTitle,
	SelectorField,
	TextareaField,
	TextField,
};

export { formFields };
