import { RadioButtonFieldProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { crmKey, fieldWidth, label, mandatory } from "@schemas/forms/presets";

const schema: Schema.FormField<RadioButtonFieldProps> = {
	schemaType: "formComponent",
	component: "RadioButtonField",
	displayName: "Radio Button Field",

	configTabs: [
		{
			title: "Content",
			fields: [
				{ ...crmKey },
				{ ...label },
				{ ...mandatory },
				{
					type: "ArrayFieldGroup",
					arrayType: "dropDown",
					title: "Options",
					key: "options",
					name: "Option",
					fields: [
						{
							type: "TextField",
							title: "Value",
							key: "value",
							mandatory: true,
						},
						{
							type: "TextField",
							title: "Text",
							key: "text",
							mandatory: true,
							humanReadable: true,
						},
						{
							type: "UniqueCheck",
							title: "",
							key: "defaultValue",
							options: [
								{
									title: "Default value",
								},
							],
						},
					],
				},
				{ ...fieldWidth },
			],
		},
	],

	default: {
		component: "RadioButtonField",
		options: [],
		fieldWidth: "100",
	},
};

export default schema;
