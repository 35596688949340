import { CustomEventFormProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	ctaLabel,
	requestChannelFields,
	utmFields,
} from "@schemas/forms/presets";
import {
	detail,
	getThemeOptions,
	heading,
	loremIpsumParagraph,
	themeSelector,
} from "@schemas/presets";

const schema: Schema.FormTemplate<CustomEventFormProps> = {
	schemaType: "formTemplate",
	displayName: "Event Form",
	component: "CustomEventForm",

	content: [
		{
			type: "NoteField",
			title: "Form path",
			key: "note",
			value: {
				title: "",
				text: 'This form is sent to the "leads" path in the CRM. It also relates the lead to the event and event call.',
			},
		},
		{ ...heading, isMockup: true, mandatory: false },
		{ ...detail },
		{ ...ctaLabel },
		{
			type: "TextField",
			key: "eventCode",
			title: "Event code",
			mandatory: true,
		},
		{
			type: "TextField",
			key: "eventId",
			title: "Event ID",
			mandatory: true,
		},
		{
			type: "TextField",
			title: "Title (1st step)",
			key: "title1",
			humanReadable: true,
			hideable: true,
		},
		{
			type: "TextField",
			title: "CTA Label (1st step)",
			key: "ctaLabel1",
			humanReadable: true,
			hideable: true,
		},
		{
			type: "FormFieldArray",
			title: "Form fields (1st step)",
			key: "formFields1",
			whiteList: [
				"TextField",
				"EmailField",
				"PhoneField",
				"NumberField",
				"EventCallsSelector",
				"SelectorField",
				"EntryDatesSelector",
				"ProgramSelector",
				"LegalField",
				"SectionTitle",
				"HiddenField",
			],
		},
		{
			type: "TextField",
			title: "Title (2nd step)",
			key: "title2",
			humanReadable: true,
			hideable: true,
		},
		{
			type: "FormFieldArray",
			title: "Form fields (2nd step)",
			key: "formFields2",
			whiteList: [
				"TextField",
				"EmailField",
				"PhoneField",
				"NumberField",
				"EventCallsSelector",
				"SelectorField",
				"EntryDatesSelector",
				"ProgramSelector",
				"LegalField",
				"SectionTitle",
				"HiddenField",
			],
		},
		{
			type: "FieldGroup",
			title: "Extra fields",
			key: "extraFields",
			fields: [
				{
					type: "NoteField",
					title: "",
					key: "extraFieldsNote",
					value: {
						title: "",
						text: "Additional fields depending on program type",
					},
				},
				...requestChannelFields.map((field) => ({ ...field, mandatory: true })),
				{
					type: "TextField",
					title: "Program interested CRM key",
					key: "programIntCrmKey",
					helptext: "For example: com_programainteresadotipo.",
					mandatory: true,
				},
				{
					type: "TextField",
					title: "Program interested value (degrees)",
					key: "programIntCrmDegrees",
					mandatory: true,
				},
				{
					type: "TextField",
					title: "Program interested value (other program types)",
					key: "programIntCrmOther",
					mandatory: true,
				},
			],
		},
		{
			type: "UniqueCheck",
			title: "Add request date",
			key: "addRequestDate",
			options: [
				{
					title: "Add com_fechaultimapeticion if updating lead info.",
				},
			],
		},
		{
			type: "TextField",
			title: "com_gestionado value",
			key: "com_gestionado",
			helptext: "com_gestionado value to send if updating lead info.",
			mandatory: false,
		},
		{ ...utmFields },
	],

	config: [
		{
			...themeSelector,
			options: getThemeOptions([
				"default",
				"default-alt",
				"accent-alt",
				"inverse",
			]),
		},
		{
			title: "Show decoration",
			key: "decoration",
			type: "RadioGroup",
			options: [
				{ name: "yes", value: true, title: "Yes" },
				{ name: "no", value: false, title: "No" },
			],
			mandatory: true,
		},
	],

	default: {
		type: "formTemplate",
		templateType: "CustomEventForm",
		title: { content: "Lorem ipsum", tag: "h3" },
		detail: loremIpsumParagraph,
		ctaLabel: "Solicitar más información",
		title1: "Información Personal",
		ctaLabel1: "Siguiente paso",
		formFields1: [],
		title2: "Otra Información",
		formFields2: [],
		requestyChannelCrmKey: "leadsourcecode",
		requestChannelDegrees: "100000008",
		requestChannelOther: "100000013",
		programIntCrmKey: "com_programainteresadotipo",
		programIntCrmDegrees: "181410000",
		programIntCrmOther: "181410001",
		addRequestDate: true,
		com_gestionado: "181410000",
		utmFields: [
			{ crmKey: "com_utm_medium", paramName: "utm_medium" },
			{ crmKey: "com_utm_source", paramName: "utm_source" },
			{ crmKey: "com_utm_campaign", paramName: "utm_campaign" },
		],
		subtheme: "default",
		decoration: false,
	},

	thumbnails: {
		"1x": "/thumbnails/forms/CustomEventForm/thumbnail@1x.png",
		"2x": "/thumbnails/forms/CustomEventForm/thumbnail@2x.png",
	},
};

export default schema;
